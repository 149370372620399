/* global filter_js_vars:readonly */

// Filtering for Avalanche
(function() {
/**
 * filterState maintains which checkboxes are checked
 * ex: {"menu" : ["1", "5"], "type" : ["6", "13"]}
 */
var filterState             = {};
var pageState               = '';
var perPageState            = 12;
var filter                  = document.getElementById('filterContainer');
var recipeGalleryDOM        = document.getElementById('filterGallery');
var filterAdvancedDOM       = document.getElementById('filterTakeover');
var filterBasicDOM          = document.getElementById('filterBasic');
var takeoverCheckboxes      = document.getElementsByClassName('js-filter-input');
var takeoverTagsDOM         = document.getElementById('filterTags');
var filterTagsIndicatorDOM  = document.getElementById('filterTagsContent');
var takeoverTaxonomyGroups  = document.getElementsByClassName('filter-header');
var takeoverButtons         = document.getElementsByClassName('js-filter-tabindex');
var takeoverMatchingResults = document.getElementById('takeOverResultsCount');
var takeoverFirstTabStop    = document.getElementById('closeFilterButton');
var takeoverLastTabStop     = document.getElementById('cancelFilterButton');
var filterScreenLg          = window.matchMedia('(min-width: 1024px)');

// IE11 is garbage
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

// Only if we are on filtering page
if(filter) {
  // Onclick routing
  filter.addEventListener('click', function (event) {
    // Checkboxes clicked
    if(event.target.matches('.js-filter-input')) {
      checkboxClicked(event.target);
    }
    // Basic Filter Category Clicked
    if(event.target.matches('.js-filter-basic')) {
      basicCategoryClicked(event.target);
    }
    // Open Button Clicked
    if(event.target.matches('#openFilterButton')) {
      openTakeoverFilter();
    }
    // Taxonomy Group Clicked
    if(event.target.matches('.filter-header')) {
      takeoverTaxonomyGroupClicked(event.target);
    }
    // Apply or Close Button Clicked
    if(event.target.matches('#applyFilterButton') | event.target.matches('#closeFilterButton')) {
      closeTakeoverFilter();
    }
    // Cancel Button Clicked
    if(event.target.matches('#cancelFilterButton') | event.target.matches('#clearFilterButton') | event.target.matches('#clearFilterButtonTakeover')) {
      clearAndCloseFilter();
    }
    // Pagination Button Clicked
    if(event.target.matches('.js-pagination')) {
      event.preventDefault();
      paginationClicked(event.target);
    }
    // Per Page Control Clicked
    if(event.target.matches('.js-per-page-control')) {
      event.preventDefault();
      perPageControlClicked(event.target);
    }
  });
  buildStatefromQuery();
}

if(filterBasicDOM) {
  setActiveCategory()
}
else if (filterAdvancedDOM) {
  // Handle Tabindex and keyboard clicks when takeover is open
  filterAdvancedDOM.addEventListener('keydown', handleTakeoverKeyPress);
  setCheckboxesFromState();
  updateTakeoverResultText();
  buildFilterTags();
  setLgScreenAttributes(filterScreenLg);
  filterScreenLg.addListener(setLgScreenAttributes);
}

function basicCategoryClicked(categoryElement) {
  var categoryTaxonomy = categoryElement.dataset.tax;
  var categoryId = categoryElement.id;
  pageState = '';
  clearFilterState();
  // Check if All was clicked
  if (categoryElement.id !== 'categoryAll') {
    filterState[categoryTaxonomy].push(categoryId);
  }
  setActiveCategory();
  completeNewRequest();
}

function checkboxClicked(checkboxElement) {
  var positionInState = filterState[checkboxElement.dataset.tax].indexOf(checkboxElement.dataset.id);
  if(checkboxElement.checked) {
    if(positionInState === -1) {
      filterState[checkboxElement.dataset.tax].push(checkboxElement.dataset.id);
    }
  } else {
    if(positionInState !== -1) {
      filterState[checkboxElement.dataset.tax].splice(positionInState, 1)
    }
  }
  buildFilterTags();
  pageState = '';
  completeNewRequest();
}

function tagClicked(tagElement) {
  var positionInState = filterState[tagElement.dataset.tax].indexOf(tagElement.dataset.id);
  if(positionInState !== -1) {
    filterState[tagElement.dataset.tax].splice(positionInState, 1);
    setCheckboxesFromState();
    buildFilterTags();
    pageState = '';
    completeNewRequest();
  }
}

function paginationClicked(paginationElement) {
  var page = paginationElement.dataset.page;
  pageState = page;
  scrollPageToFilterStart();
  completeNewRequest();
}

function perPageControlClicked(perPageControlElement) {
  pageState = 1;
  perPageState = perPageControlElement.dataset.perPage;
  scrollPageToFilterStart();
  completeNewRequest();
}

function takeoverTaxonomyGroupClicked(element) {
  var isExpanded = element.getAttribute('aria-expanded');
  if (isExpanded === 'false') {
    expandTaxomonyGroup(element);
  } else {
    closeTaxonomyGroup(element);
  }
}

function completeNewRequest() {
  // Update the query string now that we have a new filterState
  var newQueryUrlString = buildQueryFromState();
  history.replaceState(newQueryUrlString, '', newQueryUrlString);
  runAjaxRequest(newQueryUrlString);
}

function buildStatefromQuery() {
  clearFilterState()
  var queryParams = window.location.search;
  // Make sure the query string isn't just '?'
  if (queryParams.length > 1) {
    // Parse out the leading '?' query parameter, and seperate other params
    var params = queryParams.split('?')[1].split('&');
    for (var i = 0; i < params.length; i++) {
      // Parse the category name, and ID's or singular ID
      var parameterName = params[i].split('=')[0];
      var parameterValues  = params[i].split('=')[1];
      var parameterValuesArray   = parameterValues.split(',');
      // Set page state parameter
      if (parameterName === 'page') {
        pageState = parameterValues;
      }
      else if (parameterName === 'per_page') {
        perPageState = parameterValues;
      }
      else if (parameterValuesArray.length > 1) {  // Handle muliple ids per category
        for (var j = 0; j < parameterValuesArray.length; j++) {
          filterState[parameterName].push(parameterValuesArray[j]);
        }
      }
      else {  // Just single id per category
        filterState[parameterName].push(parameterValues);
      }
    }
  }
}

function setCheckboxesFromState() {
  clearCheckboxes();
  // Now check each checkbox that is in the filterState
  for (var key in filterState) {
    filterState[key].forEach(function (element) {
      var checkbox = document.getElementById(element);
      checkbox.checked = true;
    });
  }
}

function buildQueryFromState () {
  var updatedQuery = '?';
  // Get query parameters from the filterState
  for (var key in filterState) {
    if (filterState[key].length > 0) {
      updatedQuery += key + '=';
      for (var j = 0; j<filterState[key].length; j++) {
        updatedQuery += filterState[key][j];
        // Case: not the last filter id, so append ',' to seperate values
        if (j !== filterState[key].length-1) {
          updatedQuery += ',';
        }
      }
      updatedQuery += '&';
    }
  }
  // Check if we need to set the per_page query parameter
  if(perPageState !== 12) {
    updatedQuery += 'per_page=' + perPageState;
    updatedQuery += '&';
  }
  // Check if we need to set the page query parameter
  if(pageState && pageState !== 1) {
    updatedQuery += 'page=' + pageState;
  } else { // Case: no page parameter, so remove trailing '&'
    updatedQuery = updatedQuery.slice(0, -1);
  }
  // Case where no filters are selected. AJAX craps out if we provide nothing, so use '?'
  if (updatedQuery.length === 0) {
    updatedQuery = '?';
  }
  return updatedQuery;
}

// Persist any default taxonomy that might be used on the current page
function getDefaultTaxonomy() {
  var currentPageTax = document.getElementById('filterResults').getAttribute('data-current-tax');
  var currentPageTaxId = document.getElementById('filterResults').getAttribute('data-current-tax-id');
  var currentPageTaxParam = (currentPageTax && currentPageTaxId) ? '&current-taxonomy=' + currentPageTax : '';
  var currentPageTaxIdParam = (currentPageTax && currentPageTaxId) ? '&current-taxonomy-id=' + currentPageTaxId : '';
  return currentPageTaxParam + currentPageTaxIdParam;
}

// params query string in the format: ?taxonomy=category& ...
function runAjaxRequest (params) {
  var request = new XMLHttpRequest();
  request.open('POST', filter_js_vars.ajaxurl, true);
  document.getElementById('loading').style.display = 'block';
  var defaultTaxonomyParams = getDefaultTaxonomy();
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
        // If successful update HTML with response
        recipeGalleryDOM.innerHTML = this.response;
        document.getElementById('loading').style.display = 'none';
        if (filterAdvancedDOM) {
          updateTakeoverResultText();
        }
    } else {
        recipeGalleryDOM.innerHTML = this.response;
        document.getElementById('loading').style.display = 'none';
    }
  };
  request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
  //remove ? for AJAX call
  params = params.slice(1);
  request.send('action=avalanche_filter_ajax&' + params + '&posttype=' + filter_js_vars.posttype + defaultTaxonomyParams + '&noncefiltered=' + filter_js_vars.noncefiltered , function() {});
  return request;
}

function buildFilterTags() {
  // First clear all tags
  takeoverTagsDOM.innerHTML = '';
  filterTagsIndicatorDOM.innerHTML = '';
  var tagCount = 0;
  var tagFilterContainer = document.createElement('div');
  tagFilterContainer.className = 'filter-tags';
  for (var taxKey in filterState) {
    // Check that the taxonomy has any categories
    if (filterState[taxKey].length > 0) {
      // Loop through and build each category element as a child of the taxonomy
      for (var j = 0; j < filterState[taxKey].length; j++) {
        var tagCategoryButton = document.createElement('button');
        var tagCategoryIndicator;
        var tagLabel = document.getElementById(filterState[taxKey][j]).name;
        var tagIndicatorLabel = document.getElementById(filterState[taxKey][j]).name;
        tagCategoryButton.dataset.id = filterState[taxKey][j];
        tagCategoryButton.dataset.tax = taxKey;
        tagCategoryButton.innerHTML = tagLabel;
        tagCategoryButton.className = 'button filter-tag-button js-filter-tabindex';
        tagCategoryButton.setAttribute('aria-label', filter_js_vars.removetag + tagLabel);
        tagCategoryButton.addEventListener('click', function (event) {
          tagClicked(event.target);
        });
        tagCategoryIndicator = tagCategoryButton.cloneNode(true);
        tagCategoryIndicator.addEventListener('click', function (event) {
          tagClicked(event.target);
        });
        tagCategoryIndicator.className = 'filter-tag-indicator';
        tagCategoryIndicator.innerHTML = tagIndicatorLabel;
        tagFilterContainer.appendChild(tagCategoryIndicator);
        takeoverTagsDOM.appendChild(tagCategoryButton);
        tagCount++;
      }
      filterTagsIndicatorDOM.appendChild(tagFilterContainer);
    }
  }
  // create the clear filters button if there are any tags
  if (tagCount > 0) {
    var clearTagsButton = document.createElement('button');
    clearTagsButton.innerHTML = filter_js_vars.clearfilters;
    clearTagsButton.onclick = clearAll;
    clearTagsButton.className = 'button-secondary filter-tag-button js-filter-tabindex';
    takeoverTagsDOM.appendChild(clearTagsButton);
  }
}

function setActiveCategory() {
  var filters = document.getElementsByClassName('filter-current');
  var filterID = filterState[Object.keys(filterState)[0]];
  for(var i = 0; i < filters.length; i++) {
    filters[i].classList.remove('filter-current');
  }
  if(filterID.length === 0) {
    document.getElementById('categoryAll').classList.add('filter-current');
  } else {
    document.getElementById(filterID).classList.add('filter-current');
  }
}

function expandTaxomonyGroup(taxonomyGroup) {
  var taxonomyName = taxonomyGroup.dataset.slug;
  var groupToOpen = document.getElementById(taxonomyName);
  var queryStr = 'input[data-slug=' + taxonomyName + ']';
  var categoryCheckboxes = document.querySelectorAll(queryStr);
  taxonomyGroup.childNodes[3].style.transform = 'rotate(-90deg)';
  taxonomyGroup.setAttribute('aria-expanded', 'true');
  groupToOpen.setAttribute('aria-hidden', 'false');
  groupToOpen.classList.add('open');
  enableTabIndexForElements(categoryCheckboxes);
}

function closeTaxonomyGroup(taxonomyGroup) {
  var taxonomyName = taxonomyGroup.dataset.slug;
  var groupToClose = document.getElementById(taxonomyName);
  var queryStr = 'input[data-slug=' + taxonomyName + ']';
  var categoryCheckboxes = document.querySelectorAll(queryStr);
  taxonomyGroup.childNodes[3].style.transform = 'rotate(90deg)';
  taxonomyGroup.setAttribute('aria-expanded', 'false');
  groupToClose.setAttribute('aria-hidden', 'true');
  groupToClose.classList.remove('open');
  disableTabIndexForElements(categoryCheckboxes);
}

function clearAll() {
  clearCheckboxes();
  clearFilterState();
  buildFilterTags();
  completeNewRequest();
}

function clearAndCloseFilter() {
  clearAll();
  closeTakeoverFilter();
}

function clearFilterState() {
  pageState = '';
  var basicTax = (document.getElementById('filterBasic')) ? document.getElementById('filterBasic').dataset.tax : '';
  if (filterAdvancedDOM) {
    for(var x = 0; x < takeoverTaxonomyGroups.length; x++) {
      filterState[takeoverTaxonomyGroups[x].dataset.tax] = [];
    }
  } else if (basicTax) {
    filterState[basicTax] = [];
  }
}

function clearCheckboxes() {
  for (var x = 0; x < takeoverCheckboxes.length; x++) {
    takeoverCheckboxes[x].checked = false;
  }
}

function openTakeoverFilter () {
  enableTabIndexForElements(takeoverButtons);
  filterAdvancedDOM.style.height = '100%';
  document.getElementById('closeFilterButton').focus();

}

function closeTakeoverFilter () {
  closeAllTaxonomyGroups()
  disableTabIndexForElements(takeoverButtons);
  filterAdvancedDOM.style.height = '0%';
  document.getElementById('openFilterButton').focus();
}

function closeAllTaxonomyGroups() {
  for(var i = 0; i < takeoverTaxonomyGroups.length; i++) {
    closeTaxonomyGroup(takeoverTaxonomyGroups[i]);
  }
}

function scrollPageToFilterStart() {
  var filterContainer = document.getElementById('filterContainer');
  window.scroll({
    top: filterContainer.offsetTop,
    left: 0,
    behavior: 'smooth'
  });
}

function disableTabIndexForElements(elements) {
  for (var i = 0; i < elements.length; i++) {
    elements[i].tabIndex = -1;
  }
}

function enableTabIndexForElements(elements) {
  for (var i = 0; i < elements.length; i++) {
    elements[i].tabIndex = 0;
  }
}

function updateTakeoverResultText() {
  var resultsText = document.getElementById('filterResultCount').innerText;
  takeoverMatchingResults.innerHTML = resultsText;
}

function handleTakeoverKeyPress(e) {
  // Check for TAB key press
  if (e.keyCode === 9) {
    // SHIFT + TAB
    if (e.shiftKey) {
      if (document.activeElement === takeoverFirstTabStop) {
        e.preventDefault();
        takeoverLastTabStop.focus();
      }
    // TAB
    } else {
      if (document.activeElement === takeoverLastTabStop) {
        e.preventDefault();
        takeoverFirstTabStop.focus();
      }
    }
  }
  // ESCAPE
  if (e.keyCode === 27) {
    closeTakeoverFilter();
  }
}

function setLgScreenAttributes(e) {
  var filterGroupLists = Array.from(document.querySelectorAll('.filter-items'));
  if (e.matches) {
    filterGroupLists.forEach(element => element.setAttribute('aria-hidden', 'false'));
    enableTabIndexForElements(takeoverButtons);
    enableTabIndexForElements(takeoverCheckboxes);
  } else {
    filterGroupLists.forEach(element => element.setAttribute('aria-hidden', 'true'));
    disableTabIndexForElements(takeoverButtons);
    disableTabIndexForElements(takeoverCheckboxes);
  }
}

})();
